<script lang="ts" setup>
import type { GameLastWinner } from "@/types";

const SHOW_BADGE_TIMEOUT_DELAY = 6000;

const props = defineProps<{
	winner: GameLastWinner | undefined;
}>();

const { t } = useT();
const showBadge = ref(false);

watch(
	() => props.winner,
	(newData) => {
		if (!newData || showBadge.value) {
			return;
		}

		showBadge.value = true;
		setTimeout(() => {
			showBadge.value = false;
		}, SHOW_BADGE_TIMEOUT_DELAY);
	},
	{ deep: true }
);
</script>
<template>
	<ABadge
		v-if="showBadge && winner"
		class="feed-badge"
		:class="{ animated: showBadge }"
		background="var(--casablanca)"
		variant="info"
	>
		<div class="badge-content">
			<AText :modifiers="['normal']" variant="ternopil">
				{{ winner.type === "bigwin" ? t("Big Won") : t("Won") }}
			</AText>
			<template v-if="winner.type === 'win'">
				<NuxtImg
					class="icon"
					:src="`/nuxt-img/promotions/${winner.gameMode === 'SweepStakes' ? 'entries' : 'coins'}@2x.webp`"
					alt="coins"
					width="16"
					height="16"
					loading="lazy"
				/>
				<AText :modifiers="['medium']" variant="ternopil">
					{{ numberFormat(winner.data.winAmount) }}
				</AText>
			</template>
			<template v-if="winner.type === 'bigwin'">
				<ASvg class="icon" name="20/big-win" filled />
				<AText :modifiers="['bold']" variant="ternopil">
					{{ numberFormat(winner.data.big_win_coefficient) }}
				</AText>
			</template>
		</div>
	</ABadge>
</template>

<style lang="scss" scoped>
.feed-badge {
	position: absolute;
	width: max-content !important;
	border-radius: 20px !important;
	left: 0;
	right: 0;
	bottom: 80px;
	margin: 0 auto;
	color: var(--chennai) !important;

	@include media-breakpoint-down(md) {
		bottom: 60px;
	}

	.badge-content {
		display: flex;
		align-items: center;
	}

	.icon {
		margin-left: gutter(0.5);
		margin-right: gutter(0.4);
		font-size: 20px;
	}

	.player-icon {
		font-size: 12px;
		margin-right: gutter(0.5);
	}

	&.animated {
		animation: translate 6s forwards;
	}
}

@keyframes translate {
	0% {
		transform: translateY(0);
		opacity: 0;
	}

	15% {
		transform: translateY(14px);
		opacity: 1;
	}

	85% {
		transform: translateY(14px);
		opacity: 1;
	}

	100% {
		transform: translateY(0);
		opacity: 0;
	}
}
</style>
